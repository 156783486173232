// extracted by mini-css-extract-plugin
export const root = "about-module--root--7mK_Z";
export const content = "about-module--content--1E4Ax";
export const hero = "about-module--hero--1HoJv";
export const heroText = "about-module--heroText--3xLBJ";
export const heroAbout = "about-module--heroAbout--3DK_3";
export const heroAboutText = "about-module--heroAboutText--2OLOV";
export const heroAboutImage = "about-module--heroAboutImage--jdyWj";
export const heroAboutLukas = "about-module--heroAboutLukas--1GCN8";
export const heroAboutLukasText = "about-module--heroAboutLukasText--2HFIj";
export const heroAboutLukasImage = "about-module--heroAboutLukasImage--1YqHB";
export const logoGalleryWrapper = "about-module--logoGalleryWrapper--24Qpj";
export const logoGallery = "about-module--logoGallery--1lmA4";
export const logo = "about-module--logo--3MUUa";